<!--
 * @LastEditTime: 2022-11-07 10:36:36
 * @Description: 仓库内部 对象表单列表
 * @FilePath: /dwh_front/src/views/formDesign/index.vue
 * @Date: 2021-09-17 09:59
 * @Author: ZengWei
 * @LastEditors: zx
-->
<!-- eslint-disable -->
<template>
  <div v-loading="loading" class="w-full h-full" style="height: 100%;overflow-y: hidden;">
    <el-row class="h-full" style="overflow-y: auto;display: flex;flex-direction: column;height: 100%;">
      <el-col :span="1" class="h-full bd-right">
        <leftTab />
      </el-col>
      <el-col v-if="isEdit" :span="10" class="h-full p-5 bd-right">
        <parser
          class="inline-block overflow-y-auto scroller"
          style="height: calc(100% - 44px); width: 100%"
          ref="formParser"
          v-if="formDesignData"
          @submit="handleSubmit($event, 0)"
          @closeLoading="btnLoading = false"
          :form-data="formDesignData"
          :displayData="displayData"
        />
        <el-button
          type="primary"
          @click="
            btnLoading = true;
            $refs.formParser.handleSubmit();
          "
          >确 定</el-button
        >
      </el-col>
      <el-col :span="isEdit ? 13 : 24" class="h-full p-5" style="overflow: auto;">
        <!-- <el-alert
          title="点击中间图标进入表单设计界面，默认表单不可修改属性和名称，但可以设计，不允许使用默认表单直接走流程"
          type="error"
        >
        </el-alert>
        <el-alert
          title=" 已经走出流程的数据，无论使用哪个表单，都以已经走出的流程为准！"
          type="success"
          style="margin: 10px 0"
        />
        <el-alert
          title=" 凡是有标签的表单，出现的优先级高于form_uuid本身！"
          type="error"
          style="margin: 10px 0"
        /> -->
        <el-row v-if="isEdit" style="margin-bottom: 10px">
          <!-- <el-button type="primary" @click="openFormDialog">新增表单数据</el-button> -->
          <!-- <el-button type="warning" @click="openAddForm">新增表单</el-button> -->
          <strong v-if="isEdit"
            >当前正在编辑 [{{ $route.query.obname || "未知对象" }}] 的id为
            {{ data_id }} 的数据
          </strong>
        </el-row>

        <el-row class="form-containair" :gutter="15">
          <el-col span="6">
            <div class="project-item-container" @click="openAddForm">
              <div class="project-item-content">
                <i class="iconfont iconxinzeng3"></i>
                <span>新增表单</span>
              </div>
            </div>
          </el-col>
          <el-col span="6" v-for="(item, index) in formLists" :key="index">
            <div class="project-item-container" >
              <!-- <div class="project-item">
                <div @click="clickItem(item)">
                  <i class="iconfont iconbim_gongchengjisuan imgshow"></i>
                  <p class="expanded">{{ item.name ? item.name : "default" }}</p>
                  <p class="expanded">
                    {{ item.created_at ? item.created_at : "未知时间" }}
                  </p>
                </div>
                <div>
                  <el-button-group>
                    <el-button
                      class="iconfont iconshanchu"
                      size="small"
                      @click="delItem(item)"
                    ></el-button>
                    <el-button
                      @click="editItem(item)"
                      class="iconfont iconwangpan-zhongmingming1x"
                      size="small"
                    ></el-button>
                    <el-button
                      @click="openFormDialog(item)"
                      class="iconfont iconxinzeng2"
                      :class="isEdit ? 'icongis_bianji' : 'iconxinzeng2'"
                      size="small"
                    ></el-button>
                    <el-button
                      @click="subFormAdmin(item)"
                      class="iconfont iconzidingyibiaodan"
                      :class="isEdit ? 'icongis_bianji' : 'iconxinzeng2'"
                      size="small"
                    ></el-button>
                  </el-button-group>
                  <el-button
                    type="primary"
                    size="small"
                    style="margin-top: 10px"
                    @click="$globalAction('showMetaQoutes', item)"
                    >查看引用</el-button
                  >
                </div>
              </div> -->
              <div class="project-item">
                <div class="item-title" @click="clickItem(item)">
                  <span>{{ item.name ? item.name : "default" }}</span>
                  <div @click.stop="editItem(item)">
                    <i class="iconfont iconbianji2"></i>
                  </div>
                </div>
                <div class="item-content" @click="clickItem(item)">
                  <!-- <div>
                    <i class="iconfont iconform-shijian"></i>
                    引用次数
                    <span>0次</span>
                  </div> -->
                  <div>
                    <i class="iconfont iconform-shijian"></i>
                    最后编辑
                    <span>{{ item.created_at ? item.created_at : "未知时间" }}</span>
                  </div>
                </div>
                <div class="item-bottom">
                  <div class="item-bottom-item" style="background: #E8F0FE" @click="subFormAdmin(item)">
                    <i class="iconfont iconxinzeng3"></i>
                    创建子表单
                  </div>
                  <!-- <div class="item-bottom-item" style="background: #E1F5E7" @click="openFormDialog(item)">
                    <i class="iconfont iconxinzeng3"></i>
                    添加表单数据
                  </div> -->
                  <!-- <div class="item-bottom-item" style="background: #FAEEDC" @click="$globalAction('showMetaQoutes', item)">
                    <i class="iconfont iconc-search"></i>
                    查看引用
                  </div> -->
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <b-modal
      ref="elmodal"
      mode="dialog"
      title="添加对象表单数据"
      v-if="dialogVisible"
      :show="dialogVisible"
      @sure="$refs.formParser1.handleSubmit()"
      @cancel="dialogVisible = false"
    >
      <div slot="content">
        <parser
          ref="formParser1"
          v-if="dialogVisible && formDesignData"
          @submit="handleSubmit($event, 1)"
          @closeLoading="closeLoading"
          :form-data="formDesignData"
          :displayData="addData"
        />
      </div>
    </b-modal>
    <!-- '表单信息' + (addData.uuid ? ' uuid： ' + addData.uuid : '') -->
    <el-dialog
      :title="addData.name"
      :visible.sync="addFormVisible"
      width="500px"
    >
      <el-form label-width="120px" label-position="left">
        <el-form-item label="表单名称">
          <el-input v-model="addData.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="开启流程">
          <el-switch v-model="addData.need_flow"></el-switch>
        </el-form-item>
        <el-form-item label="使用上级流程">
          <el-switch v-model="addData.use_parent_flow"></el-switch>
        </el-form-item>
        <!-- <el-form-item label="默认使用的视图">
          <ViewSelect :object_uuid="$route.query.obuuid" v-model="addData.use_view_uuid"></ViewSelect>
        </el-form-item> -->
        <el-form-item label="标识名称">
          <strong>用于外部调用定位</strong>
          <el-select
            v-model="addData.use_tag_name"
            placeholder="请选择"
            multiple
            filterable
            allow-create
            style="width: 100%"
          >
            <el-option v-for="(item,index) in usageTags" :key="index" :value="item.value" :label="item.label" />
          </el-select>
        </el-form-item>
        <el-form-item label="使用下级流程">
          <el-switch v-model="addData.use_child_flow"></el-switch>
        </el-form-item>
        <el-form-item label="组织架构字段" v-if="addData.use_child_flow">
          <el-select v-model="addData.archi_field" class="w-full">
            <el-option
              v-for="item in fieldData"
              :key="item.field"
              :label="item.field_note"
              :value="item.field">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveItem">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 新增表单弹窗 -->
    <el-dialog
      title="新增表单"
      :visible.sync="showAddForm"
      width="400px"
    >
      <el-form label-width="80px" label-position="top">
        <el-form-item label="表单名称">
          <el-input v-model="addFormName"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showAddForm = false">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="createNewForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */

import leftTab from "@/views/objects/components/saasTab.vue";
import objects from "@/api/objects";
import parser from "@/components/formParser/parser_pc";
import ViewSelect from "@/components/metaSelect/ViewSelect";
import { Loading, Alert, ButtonGroup } from "element-ui";
import { mapState } from 'vuex';
import {dataInterface} from "@/apis/data";
import {viewGetFormItem} from "@/custom-component/form/newParser/scripts/itemDataCopy";

export default {
  name: "index",
  components: {
    parser, leftTab, ViewSelect,
    'el-alert': Alert,
    'el-button-group': ButtonGroup
  },
  props: {},
  data() {
    return {
      loading: true,
      btnLoading: false,
      dialogVisible: false,
      obuuid: "",
      formDesignData: null,
      data_id: null,
      displayData: {},
      addData: {
        id: 0,
        name: "",
        need_flow: 0,
        use_parent_flow: 0,
        use_child_flow: 0,
        archi_field: '',
        use_view_uuid: "",
        use_tag_name: "",
        uuid: "",
      },

      formLists: [],

      nowChooseForm: null,

      addFormVisible: false,

      fieldData: [], // 表单控件字段
      showAddForm: false,
      addFormName: ''
    };
  },
  computed: {
    isEdit() {
      if (!this.data_id) {
        return false;
      }
      return true;
    },
    ...mapState({
      usageTags: state => state.dataObjects.usageTags || null,
    }),
    ...mapState(['systemStyle'])
  },
  created() {},
  mounted() {
    if (this.$route.query.obuuid) {
      this.obuuid = this.$route.query.obuuid;
    }
    if (this.$route.query.id) {
      this.data_id = this.$route.query.id;
    }
    // this.initFormDesign();
    this.getFormLists();
    if (this.isEdit) {
      this.$message.warning("请点击表单编辑按钮进行编辑");
    }
  },
  methods: {
    /**
     * @description: 删除某一个表单数据
     * @param {*} item
     * @return {*}
     */
    delItem(item) {
      this.$confirm("确认删除吗？所有的引用都将失效", "提示")
        .then((res) => {
          if (res !== "confirm") {
            return;
          }
          this.loading = true;
          objects.deleteForm(item.id).then((res) => {
            this.loading = false;
            if (res.data.code == 200) {
              this.getFormLists();
            }
          });
        })
        .catch((Res) => {});
    },

    /**
     * @description: 编辑表单名称
     * @param {*} item
     * @return {*}
     */
    editItem(item) {
      if (item.name == "" || item.name == "default" || !item.uuid) {
        this.$message.error("默认表单不可更改");
        return;
      }
      if (typeof item.use_tag_name == "string") {
        if (item.use_tag_name.indexOf(",") > -1) {
          item.use_tag_name = item.use_tag_name.split(",");
        } else {
          item.use_tag_name = [item.use_tag_name];
        }
      }else if(!item.use_tag_name){
        item.use_tag_name = null
      }
      this.addData.name = item.name;
      this.addData.need_flow = item.need_flow ? true : false;
      this.addData.use_parent_flow = item.use_parent_flow ? true : false;
      this.addData.use_child_flow = item.use_child_flow ? true : false;
      this.addData.archi_field = item.archi_field;
      this.addData.id = item.id;
      this.addData.use_tag_name = item.use_tag_name;
      this.addData.uuid = item.uuid;
      this.getFormDesignField(item.uuid)
      this.addFormVisible = true;
      return;
    },
    /**
     *
     */
    getFormDesignField(formUuid){
      const url = `api/metadata/transform/${formUuid}`
      dataInterface({},url,'GET').then(res => {
        if(res.data.code === 200){
          const formDesignFields = res.data.data?.fields;
          if(formDesignFields instanceof Array){
            const fields = viewGetFormItem(formDesignFields);
            this.fieldData = fields;
          }
        }
      })
    },
    /**
     * @description: 保存编辑数据
     * @param {*}
     * @return {*}
     */
    saveItem() {
      if (!this.addData.name) {
        this.$message.error("名称不能为空！");
        return;
      }
      if (this.addData.name == "default") {
        this.$message.error("不可命名为保留名称");
        return;
      }
      // 验证use_tag_name是否重复，已经必然是一个数组了，嵌套循环即可

      for (let i = 0; i < this.addData.use_tag_name.length; i++) {
        const tag_name = this.addData.use_tag_name[i];
        for (let j = 0; j < this.formLists.length; j++) {
          const item = this.formLists[j];
          if (item.uuid !== this.addData.uuid) {
            if (item.use_tag_name && item.use_tag_name.indexOf(tag_name) > -1) {
              this.$message.error(
                "已存在 【" + this.getTagName(tag_name) + "】 标签的表单：" + item.name
              );
              return;
            }
          }
        }
      }

      let loading = Loading.service({ fullscreen: true, text: "处理中。。。" });

      this.addData.use_tag_name = this.addData.use_tag_name.join(",");
      objects
        .editFormName(this.addData.id, this.addData)
        .then((res) => {
          loading.close();
          if (res.data.code == 200) {
            this.addFormVisible = false;
            this.addData = {
              name: "",
              need_flow: 0,
              use_parent_flow: 0,
              use_child_flow: 0,
              archi_field: '',
              use_view_uuid: "",
              use_tag_name: "",
              uuid:''
            };
            this.getFormLists();
          }
        })
        .catch((res) => {
          this.loading = false;
        });
    },

    /**
     * @description: 点击后进入表单设计页面
     * @param {*} item
     * @return {*}
     */
    clickItem(item) {
      let needFlow = !!item.need_flow;
      this.$router.push({
        path:
          "/dwh/objects/design-form?obuuid=" +
          this.obuuid +
          "&obname=" +
          this.$route.query.obname +
          "&type=field&uuid=" +
          item.uuid +
          "&name=" +
          item.name +
          "&needFlow=" +
          needFlow,
      });
    },
    /**
     * @description: 数打开表单新增
     * @param {*}
     * @return {*}
     */
    openAddForm() {
      this.addFormName = ''
      this.showAddForm = true
    },
    /**
     * @description: 数据名称新增一个空数据的表单
     * @param {*}
     * @return {*}
     */
    createNewForm() {
      if (!this.addFormName) {
        return this.$message.warning('请填写表单名称！')
      }
      this.loading = true;
      this.initFormDesign().then((res1) => {
        this.loading = true;
        objects
          .saveObjectDesign({
            name: this.addFormName,
            object_uuid: this.obuuid,
            form_json: JSON.stringify(this.formDesignData),
          })
          .then((res) => {
            if (res.data.code == 200) {
              this.loading = false;
              this.showAddForm = false
              this.getFormLists();
            }
          })
          .catch((res) => {
            this.loading = false;
            this.showAddForm = false
          });
      });
    },

    /**
     * @description: 获取标签的名称
     * @param {String} item 标签键值
     * @return {String} 标签的名称
     */
    getTagName(item) {
      this.usageTags.forEach((tag) => {
        if (tag.value == item) {
          item = tag.label;
        }
      });
      return item
    },

    /**
     * @description: 获取本对象所有配置的表单
     * @param {*}
     * @return {*}
     */
    getFormLists() {
      this.loading = true;
      objects.getFormList(this.obuuid).then((res) => {
        if (res.data.code == 200) {
          this.formLists = res.data.data;
        }
        this.loading = false;
      });
    },

    /**
     * @description: 初始化表单配置数据 以及编辑时的回显数据
     * @param {String} uuid  选择的表单uuid
     * @return {*} Promise
     */
    initFormDesign(uuid = "") {
      return new Promise((resolve, reject) => {
        objects.transFieldsUUid(uuid || this.obuuid).then((res) => {
          if (res.data.code == 200) {
            if (this.isEdit) {
              // 获取已经有的数据进行绑定
              let config = res.data.data;
              let ruuid = [];
              for (let i = 0; i < config.fields.length; i++) {
                let item = config.fields[i];
                if (item.__config__.tagIcon == "form") {
                  ruuid.push({ relationship_uuid: item.__vModel__ });
                }
              }
              objects.mapi
                .getDetail(this.obuuid, this.data_id, "", ruuid)
                .then((res1) => {
                  let data = res1.data.data;
                  this.formDesignData = res.data.data;
                  this.displayData = data;
                  this.loading = false;
                  resolve(true);
                });
            } else {
              this.formDesignData = res.data.data;
              this.loading = false;
              resolve(true);
            }
          } else {
            reject();
          }
        });
      });
    },

    closeLoading() {
      //关闭弹框里按钮loading
      if (this.$refs.elmodal && this.$refs.elmodal.loading) {
        this.$refs.elmodal.loading = false;
      }
    },
    openFormDialog(item) {
      this.loading = true;
      this.formDesignData = "";
      this.initFormDesign(item.uuid || this.object_uuid)
        .then((res) => {
          this.loading = false;
          if (!this.isEdit) {
            this.dialogVisible = true;
          }
        })
        .catch((res) => {
          this.loading = false;
        });
    },
    subFormAdmin(item){
      let needFlow = !!item.need_flow;
      this.$router.push({
        path:
          "/dwh/objects/sub-forms?obuuid=" +
          this.obuuid +
          "&obname=" +
          this.$route.query.obname +
          "&type=field&uuid=" +
          item.uuid +
          "&name=" +
          item.name +
          "&needFlow=" +
          needFlow,
      });
    },
    handleSubmit(formSubmitData, type) {
      // formSubmitData.object_uuid = this.obuuid
      // formSubmitData.__method_name__ = 'createData'
      console.log(formSubmitData, "final data", type);
      if (type == 0) {
        objects.mapi
          .editOne(this.obuuid, this.data_id, formSubmitData)
          .then((res) => {
            console.log(res, "edit one");
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "编辑成功",
              });
            }
          });
      } else {
        objects.mapi.addOne(this.obuuid, formSubmitData).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.dialogVisible = false;
            this.$message({
              type: "success",
              message: "新增成功",
            });
          } else {
            this.closeLoading();
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
  @import "../../manage-views/css/manageAdd.less";
  /* 新版样式 */
  .form-containair {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .project-item-container {
      width: 100%;
      height: 218px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      margin: 0 16px 16px 0;
      box-sizing: border-box;
      background: #fff;
      /* 新增 */
      .project-item-content {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #F3F7FE;
        i {
          color: #337EFF;
          font-size: 28px;
        }
        span {
          color: #337EFF;
          margin-top: 8px;
        }
      }
      .project-item {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .item-title {
          cursor: pointer;
          width: 100%;
          height: 36px;
          display: flex;
          justify-content: space-between;
          background-color: #fff;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #2A2F3D;
          line-height: 24px;
          div {
            width: 24px;
            height: 24px;
            background: #F2F4F7;
            border-radius: 4px;
            text-align: center;
            color: #6D768F;
          }
        }
        .item-content {
          cursor: pointer;
          width: 100%;
          height: 90px;
          background: #F5F7FA;
          border-radius: 6px;
          display: flex;
          flex-direction: column;
          div {
            flex: 1;
            display: flex;
            align-items: center;
            color: #2A2F3D;
            i {
              margin: 0 16px;
            }
            span {
              margin-left: 16px;
            }
          }
        }
        .item-bottom {
          width: 100%;
          flex: 1;
          display: flex;
          margin-top: 20px;
          justify-content: flex-start;
          .item-bottom-item {
            cursor: pointer;
            width: 110px;
            height: 32px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            padding: 7px 8px;
            box-sizing: border-box;
            color: #2A2F3D;
            font-size: 12px;
            margin-right: 8px; // 如果父级更改justify-content  则取消此样式即可
            i {
              margin-right: 4px;
            }
          }
        }
      }
    }
  }
  .h-full {
    width: 100%;
    height: auto;
    .top-tool {
      height: 60px;
    }
  }
.formCard {
  float: left;
  border: 1px solid black;
  margin: 5px 0 5px 5px;
  border-radius: 4px;
  text-align: center;
  width: 180px;
  height: 80px;
}
.formName {
  height: 50px;
  font-size: 18px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
/* .project-item {
  width: 200px;
  float: left;
  box-sizing: border-box;
  padding: 10px;
  margin-top: 5px;
  border-radius: 6px;
  margin-right: 15px;
  margin-bottom: 15px;
  border: 1px solid rgba(72, 134, 255, 0.1);
  text-align: center;
  position: relative;
  &:hover {
    box-shadow: 0px 0px 3px 2px rgba(72, 134, 255, 0.1);
    cursor: pointer;
  }
  .imgshow {
    height: auto;
    margin: auto;
    font-size: 45px;
    color: #4f91fe;
    line-height: 70px;
  }
  p {
    height: 28px;
    line-height: 14px;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-break: break-all;
  }
  span {
    i {
      font-size: 18px;
      margin-left: 5px;
    }
  }
} */
</style>
